export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,4,3];

export const dictionary = {
		"/": [6],
		"/[country=global_country]/[language=global_language]/_crp_": [8,[2]],
		"/[country=global_country]/[language=global_language]/_crp_/email": [9,[2]],
		"/[country=global_country]/[language=global_language]/_crp_/logout": [10,[2]],
		"/[country=local_country]/[language=any_language]/builder-section-preview/[model_name]/[[entry_id]]": [16,[4]],
		"/[country=global_country]/[language=global_language]/builder-section-preview/[model_name]/[[entry_id]]": [11,[3]],
		"/[country=local_country]/[language=any_language]/digital-entitlement": [17,[5]],
		"/[country=local_country]/[language=any_language]/digital-entitlement/activate-code": [18,[5]],
		"/[country=local_country]/[language=any_language]/digital-entitlement/contact-address-info": [~19,[5]],
		"/[country=local_country]/[language=any_language]/digital-entitlement/redeem-service-code": [20,[5]],
		"/[country=global_country]/[language=global_language]/insights": [12],
		"/[country=global_country]/[language=global_language]/insights/post/[...rest]": [14],
		"/[country=global_country]/[language=global_language]/insights/[...catchall]": [13],
		"/[country=local_country]/[language=any_language]/schneider-in-america": [21],
		"/[country=local_country]/[language=any_language]/techcomm-edit-symbol/[...catchall]": [23],
		"/[country=local_country]/[language=any_language]/techcomm/[...catchall]": [22],
		"/[country=global_country]/[language=global_language]/[...catchall]": [7],
		"/[country=local_country]/[language=any_language]/[...catchall]": [~15]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';